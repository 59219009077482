.auth-register,
.auth-login,
.auth-profile {
  input {
    color: $base-foreground-color;
  }
}

.forgotten-password a {
  color: #ccd;
}

.fm-property-confirm_password {
  visibility: hidden;
  height: 0;
}

.fm-property-other_message label {
  @extend %visually-hidden;
}

@include media-query(palm) {
  .fm-fields--mobile-profile {
    //margin-bottom : $base-spacing-unit;

    p {
      padding: 0 $base-spacing-unit;
    }

    .mobile-picker-button {
      padding: $base-spacing-unit;
      background: rgba(255, 255, 255, 0.8) url(images/forward-blue@2x.png)
        no-repeat center right;
      background-size: 24px 20px;
      color: $inuit-base-text-color;
      margin-bottom: 1px;
    }

    .mobile-picker-button__label {
      font-size: 18px;
    }
    .mobile-picker-button__value {
      font-size: 14px;
    }

    .mobile-picker-button--has-value {
      background-color: rgba(255, 255, 255, 0.1);
      color: #fff;

      .mobile-picker-button__label {
        font-size: 14px;
      }
      .mobile-picker-button__value {
        font-size: 18px;
      }
    }
  }

  .ui-navigation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    //bottom: 0;
    //right: 0;
    z-index: 1000;
    overflow: auto;
  }

  .ui-screen {
    background-color: #fff;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    //right: 0;
    //bottom: 0;
  }

  .ui-screen__inner {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    position: absolute;
    padding-top: 66px;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    //right: 0;
    //bottom: 0;
  }

  .ui-screen--entering {
    transform: translateX(100%);
    transition: transform 0.26s ease-out;
  }

  .ui-screen--enter {
    transform: translateX(0);
    transition: transform 0.26s ease-out;
  }

  .ui-screen--leaving {
    transform: translateX(0);
    transition: transform 0.26s ease-out;
  }

  .ui-screen--leave {
    transform: translateX(100%);
    transition: transform 0.26s ease-out;
  }

  .ui-screen--entering.ui-screen--root {
    transform: translate3d(0, 100%, 0);
    transition: transform 0.26s ease-out;
  }

  .ui-screen--enter.ui-screen--root {
    transform: translate3d(0, 0, 0);
    transition: transform 0.26s ease-out;
  }

  .ui-screen--leaving.ui-screen--root {
    transform: translateY(0);
    transform: translate3d(0, 0, 0);
    transition: transform 0.26s ease-out;
  }

  .ui-screen--leave.ui-screen--root {
    transform: translate3d(0, 100%, 0);
    transition: transform 0.26s ease-out;
  }

  .ui-navbar {
    background-color: #4b9bef;
    color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 66px;
    z-index: 100;
  }

  .ui-navbar__title {
    text-align: center;
    padding: 20px 40px;
    font-weight: normal;
  }

  .ui-navbar__back {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 22px;
    left: 22px;
    background: url(images/back-white@2x.png) no-repeat;
    background-size: contain;
  }

  .ui-navbar__button {
    position: absolute;
    top: 22px;
    right: 22px;
    font-size: 90%;
  }

  .ui-list {
    background-color: #fff;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  .ui-list__cell {
    padding: $base-spacing-unit;
    border-bottom: 1px solid #d8d8d8;
  }

  .ui-list__footer {
    margin: $half-spacing-unit 0;
    text-align: center;
  }

  input.ui-list__search {
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid #d8d8d8;
    padding: $half-spacing-unit $base-spacing-unit;
  }

  .ui-list--multi {
    .ui-list__cell {
      background: transparent url(images/checkbox-blue-round-off@2x.png)
        no-repeat center right;
      background-size: 36px 36px;
      padding-right: 40px;
      color: $low-text-color;
      margin-right: 20px;
    }

    .ui-list__cell--checked {
      background: transparent url(images/checkbox-blue-round-on@2x.png)
        no-repeat center right;
      background-size: 36px 36px;
      color: $base-text-color;
      margin-right: 20px;
    }
  }

  .page-body--disabled {
    height: 1px;
    overflow: hidden;
    position: absolute;
  }
}

input.fm-property-primary_spec__search {
  background: #fff url(images/caret@3x.png) no-repeat center right;
  background-size: 21px 6px;
  font-size: 90%;
  cursor: pointer;
  padding: 11px 10px;
}

input.fm-property-primary_spec__search::placeholder {
  color: $inuit-base-text-color;
  transition: color 0.16s ease-out;
}

input.fm-property-primary_spec__search:focus::placeholder {
  color: $low-text-color;
}

ul.ui-autocomplete {
  margin-top: -1px;
}
