/**
 * Modals, taken from bootstrap
 */
.modal-open .modal .dropdown-menu {
  z-index: 2050;
}

.modal-open .modal .dropdown.open {
  *z-index: 2050;
}

.modal-open .modal .popover {
  z-index: 2060;
}

.modal-open .modal .tooltip {
  z-index: 2080;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop,
.modal-backdrop.fade.in {
  opacity: 0.8;
  filter: alpha(opacity=80);
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1050;
  width: 560px;
  margin: -250px 0 0 -280px;
  overflow: auto;
  background-color: #ffffff;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.3);
  *border: 1px solid #999;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding-box;
  background-clip: padding-box;
}

.modal.fade {
  -webkit-transition: opacity 0.3s linear;
  -moz-transition: opacity 0.3s linear;
  -o-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
}

.modal-header {
  padding: 9px 15px;
  border-bottom: 1px solid #eee;
  background: $brand-color none;

  position: relative;
  padding-right: 35px; // so that close button doesn't overlap title
  .btn-close-dialog {
    text-indent: -9999em;
    border: 0;
    background-color: transparent;
    @extend .fa;
    @extend .fa-close;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 14px;
    right: 15px;
  }
}

.modal-header .close {
  margin-top: 2px;
}

.modal-header h3 {
  margin: 0;
  line-height: 30px;
  color: #fff;
  text-shadow: none;
}

.modal-header p {
  color: #fff;
  margin: 0;
}

.modal-body {
  max-height: 400px;
  padding: 15px;
  overflow-y: auto;
}

.modal-form {
  margin-bottom: 0;
}

.modal-footer {
  padding: 14px 15px 15px;
  margin-bottom: 0;
  text-align: right;
  background-color: $off-white;
  border-top: 1px solid #ddd;
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
  *zoom: 1;
  -webkit-box-shadow: inset 0 1px 0 #ffffff;
  -moz-box-shadow: inset 0 1px 0 #ffffff;
  box-shadow: inset 0 1px 0 #ffffff;
}

.modal-footer:before,
.modal-footer:after {
  display: table;
  line-height: 0;
  content: '';
}

.modal-footer:after {
  clear: both;
}

.modal-footer .btn + .btn {
  margin-bottom: 0;
  margin-left: 5px;
}

.modal-footer .btn-group .btn + .btn {
  margin-left: -1px;
}
