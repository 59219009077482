.guest-page-header {
  background-color: $guest-header-background-color;
  color: $guest-header-foreground-color;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 80px;
  z-index: 100;
  overflow: auto;
  .auth-actions-menu {
    position: absolute;
    right: $twice-spacing-unit;
    top: 0;
  }
  .auth-actions-list {
    margin: $base-spacing-unit 0 0;
    .btn {
      margin-left: 1em;
    }
  }

  .editor-errors {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 80px;
    background-color: $danger-color;
    padding: 0 (2 * $base-spacing-unit) 0;
    color: #fff;
    text-align: center;
    font-weight: bold;
    display: table;
    width: 100%;
  }

  .editor-errors__text {
    display: table-cell;
    vertical-align: middle;
  }

  .editor-errors__close {
    position: absolute;
    top: 50%;
    margin-top: -20px;
    right: $base-spacing-unit;
    cursor: pointer;
  }
}

.js-keyboard-visible .guest-page-header {
  position: absolute;
}

.guest-page-header__inner {
  @include responsive-page-width();
  padding: 0 $base-spacing-unit;
  h1 {
    margin: 0 auto;
    width: 50px;
  }
}

.guest-page-header__home {
  background: transparent url(images/logo-white.png) no-repeat center center;
  background-size: contain;
  width: 50px;
  height: 45px;
  display: block;
  margin: 17px auto 0;
  text-indent: 100px;
  overflow: hidden;
  border: 0;
  appearance: none;
}

.guest-page-header__page-title {
  @extend %visually-hidden;
}

.guest-page-header__cancel-join {
  display: inline;
}

@include media-query(palm) {
  .guest-page-header__inner h1 {
    margin: 0;
  }

  .guest-page-header .auth-actions-list {
    .btn {
      margin-left: 0.5em;
    }
  }

  .guest-page-header__not-member,
  .guest-page-header__already-member {
    font-size: 80%;
  }

  .guest-page-header {
    .auth-actions-menu {
      font-size: 14px;
      right: $base-spacing-unit;
    }
  }
}
