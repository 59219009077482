.alerts {
  @include responsive-page-width();
  padding-top: $half-spacing-unit;
}

.alert,
.alert-info {
  text-align: center;
  margin: 0 0 $half-spacing-unit 0;
  padding: $half-spacing-unit $base-spacing-unit;
  border-radius: 3px;

  color: #fff;
  border: 1px solid darken($brand-color, 5%);
  background-color: lighten($brand-color, 0%);

  a {
    color: #fff;
  }
}

.alert-warning {
  border: 1px solid darken($apply-color, 5%);
  background-color: lighten($apply-color, 0%);
}

.alert-error {
  border: 1px solid darken($danger-color, 5%);
  background-color: lighten($danger-color, 0%);
}

.alert-success {
  border: 1px solid darken($success-color, 5%);
  background-color: lighten($success-color, 0%);
}

.ff-toggle {
  text-align: right;
}
