.editor-specialties__fields {
  list-style-type: none;
  margin: 0;
}

.editor-specialties__field {
  cursor: pointer;
  display: block;
  padding: 2px 0;
  .fa {
    color: $low-text-color;
    margin-right: 1em;
    transition: transform 0.26s ease-out;
  }
}

.field--open .editor-specialties__field .fa {
  transform: rotate(90deg);
}

.editor-specialties__specialties {
  list-style-type: none;
  margin: 0 0 0 28px;
  padding-bottom: $half-spacing-unit;
  overflow: hidden;
}

.editor-specialties--without-subspecialties .editor-specialties__specialties {
  li {
    float: left;
    width: 20em;
    margin: 0;
  }
}

.editor-specialties__subspecialties {
  list-style-type: none;
  margin: 0 0 0 46px;
  overflow: hidden;
  li {
    float: left;
    width: 11em;
  }
}

.editor-specialties__specialties,
.editor-specialties__subspecialties {
  label {
    display: block;
    margin: 5px 0;
    color: $low-text-color;
    font-size: 80%;
    padding: 15px 0 13px 46px;
  }
  input[type='checkbox'] {
    float: right;
    visibility: hidden;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px);
  }
  input[type='checkbox'] + label {
    background: transparent url(images/checkbox-off@2x.png) no-repeat center
      left;
    background-size: 36px 36px;
  }

  input[type='checkbox']:checked + label {
    background: transparent url(images/checkbox-on@2x.png) no-repeat center left;
    background-size: 36px 36px;
    color: $base-text-color;
  }
}
