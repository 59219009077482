.tabs {
  @extend .nav;

  a {
    margin-right: 0.5em;
    padding: 5px 10px;
  }
}

.tab-panel {
  padding: $base-spacing-unit 0;
}

.tab-panel--hidden {
  @include visually-hidden();
}

.tab-link--current {
  color: $low-text-color;
}
