$fa-font-path: 'font-awesome-fonts';

$page-max-width: 1280px;

$inuit-base-text-color: #314559;

$inuit-base-font-size: 16px;
$inuit-base-line-height: 22px;

$inuit-heading-size-1: 44px !default;
$inuit-heading-size-2: 34px !default;
$inuit-heading-size-3: 24px !default;
$inuit-heading-size-4: 20px !default;
$inuit-heading-size-5: 16px !default;
$inuit-heading-size-6: 14px !default;

$inuit-heading-size-1--palm: 34px !default;
$inuit-heading-size-2--palm: 28px !default;
$inuit-heading-size-3--palm: 24px !default;
$inuit-heading-size-4--palm: 20px !default;
$inuit-heading-size-5--palm: 16px !default;
$inuit-heading-size-6--palm: 14px !default;

$inuit-enable-media--small: true;
$inuit-enable-media--rev: true;

$base-font-size: $inuit-base-font-size;
$small-font-size: $base-font-size - 2;
$mini-font-size: $small-font-size - 2;

$base-foreground-color: $inuit-base-text-color;
$base-background-color: #fff;
$base-border-color: #ccc;
$base-border: 1px solid $base-border-color;

$low-border-color: #e8e8e8;

$base-border-radius: 3px;

$separator-border-color: #8fbae3;
$separator-border: 1px solid $separator-border-color;

$base-accent-color: #1b6ebf;

$brand-color: #1b6ebf;
$brand-face: 'Helvetica Neue', Arial, 'Lucida Grande', sans-serif;

$brand-round: 3px;

$link-color: #1b6ebf;

$light-link-color: #4b9bef;

$off-white: #f2f2f2;
$light-grey: #ccc;

$dark-background-color: #021740;
$mid-background-color: #0b346a;

$footer-background-color: #021740;

$search-bar-background-color: #021740;
$search-bar-foreground-color: #fff;
$search-bar-action-color: #fff;
$search-bar-link-color: #59acff;

$guest-header-background-color: rgba(0, 22, 64, 0.8);
$guest-header-foreground-color: #fff;

$full-header-gradient-start: #0b346a;
$full-header-gradient-mid: #184883;
$full-header-gradient-end: #1b6ebf;

$compact-header-background-color: $mid-background-color;

$header-foreground-color: #fff;
$header-link-color: #509dec;

$low-text-color: #999;
$extra-low-text-color: #ccc;

$apply-color: #f90;
$danger-color: #f30;
$danger-color: #d0021b;
$success-color: #1dbf97;

// Forms
$form-field-border-color: $low-border-color;
$form-border-color: $base-border-color;
$form-border-color-hover: darken($base-border-color, 10);
$form-border-color-focus: $base-accent-color;
$form-border-color-error: $danger-color;
$form-border-radius: $base-border-radius;
$form-box-shadow: inset 0 1px 3px rgba(black, 0.06);
$form-box-shadow-focus: $form-box-shadow,
  0 0 5px rgba(darken($form-border-color-focus, 5), 0.7);
$form-font-size: $inuit-base-font-size;
$form-font-family: $brand-face;

// whether to use u-1/3 or u-1-of-3 size classes
$inuit-use-fractions: false;

$inuit-widths-columns: (1, 2, 3, 4, 5, 12) !default;

$inuit-widths-columns-responsive: (1, 2, 3, 4, 5, 12) !default;
