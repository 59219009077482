@use "sass:math";

html {
  -webkit-font-smoothing: antialiased;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.25),
  only screen and (min-device-pixel-ratio: 1.25),
  only screen and (min-resolution: 200dpi),
  only screen and (min-resolution: 1.25dppx) {
    html {
      -webkit-font-smoothing: subpixel-antialiased;
    }
}

.layout-landing {
  .page-body {
    background-color: #fff;
  }
}

.page-landing {
  position: relative;
  &:before,
  &:after {
    content: '';
    display: block;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
    height: 99%;
    //@include media-query(palm) {
    //  height : 96%;
    //}
  }
  &:before {
    background: #111016 url(images/homepage-photo@2x.jpg) no-repeat center top;
    //background: linear-gradient(90deg, rgba(0,0,0,0), rgba(0,0,0,0.5)) no-repeat center right, #111016 url(images/homepage-photo@2x.jpg) no-repeat center top;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5))
        no-repeat center right,
      #111016 url(images/signup-photo.jpg) no-repeat center top;
    background-size: 99% 100%, cover;
  }
  &:after {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
  }
}

.landing__banner {
  overflow: hidden;
  position: relative;
  max-height: 525px;
}

@include media-query(palm) {
  .landing__banner {
    max-height: auto;
    height: auto;
  }
}

.landing__benefitspage {
  background-color: white;
  position: relative;
}

.page-landing--signup::before {
  background: #111016 url(images/signup-photo.jpg) no-repeat center top;
  background-size: cover;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5))
      no-repeat center right,
    #111016 url(images/signup-photo.jpg) no-repeat center top;
  background-size: 66% 100%, cover;
}

.page-landing__inner {
  color: #fff;
  width: 100%;
  position: relative;
  z-index: 20;
  padding: 80px 0 0;
}

.landing__title {
  font-size: $inuit-heading-size-2;
  font-weight: 300;
  margin: 0 0 0.25em;
  line-height: 1.2;
  max-height: 3.5em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.landing__description {
  opacity: 0.8;
}

@media screen and (max-height: 480px) {
  .landing__description {
    margin-bottom: $half-spacing-unit;
  }
}

.landing__content {
  font-weight: 400;
}

.landing__content--a {
  display: block;
}

.landing__content--b {
  display: none;
}

.ab-signup-b {
  .landing__content--a {
    display: none;
  }
  .landing__content--b {
    display: block;
    width: 25em;
    max-width: 100%;
  }
}

.landing__content--other-thanks {
  text-align: center;
  min-height: 200px;
}

@include media-query(palm) {
  .landing__title {
    font-size: $inuit-heading-size-2--palm;
  }
  ul.landing__description {
    margin-left: 0;
    list-style-type: none;
    li {
      margin-bottom: 1em;
    }
  }
}

// todo delete after refactor
//.page-landing__inner {
//  background : linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, .5));
//}
.landing__banner {
  position: relative;
  overflow: hidden;
}

.landing__banner-left {
  position: relative;
}

@include media-query(palm) {
  .landing__intro-inner {
    width: 70%;
    min-width: 320px;
    margin: 0 auto;
    padding: $base-spacing-unit $half-spacing-unit;
  }
}

@include media-query(lap-and-up) {
  .landing__device-preview {
    position: absolute;
    top: 44px;
    right: 0;
    // design in components.device.scss
  }
  .landing__intro-inner {
    padding-top: 44px;
    padding-bottom: 88px;
    padding-left: 12.5%;
    width: 80%;
  }
}

.landing__content fieldset {
  padding: 0;
}

.landing--join .auth-register fieldset,
.auth-login .landing__content fieldset {
  .fm-field label {
    @include visually-hidden();
  }
}

.landing--login-join .landing__content fieldset {
  margin-bottom: 7px;
}

.fm-property-registration_id {
  .fm-description {
    font-size: 90%;
    margin-top: -0.5em;
    margin-bottom: 0;
  }
}

.fm-property--terms {
  text-shadow: #000 0px 0px 30px;
  a {
    color: #ccd;
  }
}

.fm-property--terms__check {
  @include visually-hidden();
}

.fm-property--terms__check + .fm-property--terms__label {
  background: transparent url(images/checkbox-grey-unchecked@2x.png) no-repeat 0
    2px;
  background-size: 18px;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
}

.fm-property--terms__check:checked + .fm-property--terms__label {
  background: transparent url(images/checkbox-grey-checked@2x.png) no-repeat 0
    2px;
  background-size: 18px;
}

.fm-property--terms__check:focus + .fm-property--terms__label {
  outline: white auto 1px;
  outline: -webkit-focus-ring-color auto 1px;
  outline-offset: 1px;
}

.fm-property--terms__label {
  font-weight: normal;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  padding-left: 32px;
  min-height: 20px;
}

.fm-property--terms--error .fm-property--terms__label {
  color: #eef;
  a {
    color: #fff;
  }
}

.fm-property--marketing .fm-property--terms__label {
  padding-top: 3px;
}

@include media-query(palm) {
  .landing--login .landing__content .fm-footer {
    padding: 0 0 $base-spacing-unit;
  }
  .landing--join .landing__content .fm-footer {
    padding: 0 0 $base-spacing-unit;
    text-align: center;
  }
}

@include media-query(desk) {
  .landing__content .auth-register fieldset {
    width: 300px;
  }
  .landing--login fieldset,
  .landing--login-join .landing__content fieldset {
    width: 370px;
  }
}

/*
 * App download links
 *
 */

.page-landing__download-links {
  position: absolute;
  z-index: 100;
  right: 0;
  bottom: 0;
  left: 0;
  height: 61px;
  background: transparent
    linear-gradient(
      90deg,
      rgba(216, 223, 227, 1),
      rgba(236, 239, 241, 0.1) 50%,
      rgba(255, 255, 255, 0)
    );
  padding: $half-spacing-unit $base-spacing-unit;
}

.app-download-links {
  // height: 88px;
  background-color: rgba(216, 223, 227, 0.9);
  padding: $half-spacing-unit 5px;
  text-align: center;
  position: relative;
  border-top: 22px solid white;
  border-bottom: 22px solid #021740;
  margin-bottom: -22px;
}

/*
 * Social Proof section
 *
 */

@mixin carousel-animation($frames, $static, $name: 'carousel') {
  $animating: math.div(99% - ($frames * $static), $frames - 1);
  //@at-root {
  @keyframes #{$name} {
    @for $i from 0 to $frames {
      $current-frame: ($i * $static) + ($i * $animating);
      $next-frame: (($i + 1) * $static) + ($i * $animating);
      $halfway-frame: math.div($i *
        $static, 1%) +
        ($i - 1) *
        $animating +
        $animating *
        0.5;
      @if $i>0 {
        #{$halfway-frame} {
          filter: blur(2px);
        }
      }
      #{$current-frame,
      $next-frame} {
        transform: translateX(math.div($i * -100%, $frames));
        filter: blur(0);
      }
    }
    99% {
      opacity: 1;
    }
    99.4% {
      opacity: 0;
      transform: translateX(math.div(($frames - 1) * -100%, $frames));
    }
    99.5% {
      opacity: 0;
      transform: translateX(0);
    }
    100% {
      opacity: 1;
    }
  }
  //}
}

.social-proof {
  background-color: rgba(216, 223, 227, 0.25);
  // margin-bottom: $base-spacing-unit;
  border-bottom: $base-spacing-unit solid white;
  overflow: hidden;
  padding: $base-spacing-unit 0;
  position: relative;
  z-index: 5;
  background-color: #f5f7f8;
  @include media-query(lap) {
    border-bottom: 0;
  }
  @include media-query(palm) {
    border-bottom: 0;
    padding-bottom: $base-spacing-unit * 0.5;
  }
}

.social-proof__logos {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  li {
    margin: 6px;
  }
  @include media-query(lap-and-up) {
    li {
      margin: 6px $base-spacing-unit - 2 5px;
    }
  }
}

.social-proof__alt {
  @extend %visually-hidden;
}

.social-proof__logo {
  display: inline-block;
  width: 114px;
  height: 40px;
  background-size: 114px 40px;
}

.social-proof__logo--wide {
  display: inline-block;
  width: 171px;
  height: 40px;
  background-size: 171px 40px;
}

@include media-query(palm) {
  .social-proof__logo {
    width: 90px;
    height: 32px;
    background-size: 90px 32px;
  }
  .social-proof__logo--wide {
    width: 137px;
    height: 32px;
    background-size: 137px 32px;
  }
}

/*
 * Benefits section
 *
 */

.flayout {
  @extend %clearfix;
  border-bottom: 20px solid white;
}

.flayout__item {
  float: left;
}

@include media-query(desk) {
  .benefit--video__link {
    background: url(https://static.medshr.net/video/medshr-for-doctors.jpg)
      no-repeat 50% 50%;
    background-size: cover;
    display: block;
    height: 170px;
    position: relative;
  }
  .benefit--video:hover .benefit--video__link:before {
    text-shadow: 0 0 1em rgba(255, 255, 255, 0.4);
    /* transition */
    -webkit-transition: all 0.1s;
    -moz-transition: all 0.1s;
    -o-transition: all 0.1s;
    transition: all 0.1s;
  }
  .benefit--video__link:before {
    content: '\e001';
    font-family: VideoJS;
    color: #fff;
    text-shadow: 0 0 0.1em #000;
    position: absolute;
    top: 50%;
    line-height: 1;
    font-size: 50px;
    margin-top: -25px;
    width: 100%;
    text-align: center;
  }
}

.video-js-responsive-container {
  width: 100%;
  position: relative;
}

@include media-query(portable) {
  .video-js-responsive-container.vjs-hd {
    padding-top: 52.6%;
  }
  .video-js-responsive-container.vjs-sd {
    padding-top: 75%;
  }
  .video-js-responsive-container .video-js {
    height: 100% !important;
    width: 100% !important;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.video-lightbox-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  background-color: #333;
  background-color: rgba(0, 0, 0, 0.7);
  .video-js-responsive-container {
    margin: 0 auto;
  }
  .video-js {
    width: 100%;
    height: 100%;
  }
}

.video--promo {
  background-color: #000;
  width: 100%;
}

@include media-query(desk) {
  .page-landing__benefits {
    padding: 0 $base-spacing-unit $twice-spacing-unit;
  }
}

@include media-query(lap) {
  .page-landing__benefits {
    padding: 0 $twice-spacing-unit $twice-spacing-unit;
  }
}

.benefit--text {
  color: #1b6ebf;
  position: relative;
}

@include media-query(portable) {
  .benefit--text {
    height: 150px;
    border-bottom: 1px solid #d8dfe3;
  }
  .page-landing__benefits {
    margin-bottom: $twice-spacing-unit;
  }
}

@include media-query(desk) {
  .benefit--video {
    height: 170px;
  }
  .benefit--text {
    height: 170px;
    border-right: 1px solid #d8dfe3;
  }
}

.page-landing__benefit-text {
  position: absolute;
  bottom: $half-spacing-unit;
  right: 0;
  left: 0;
  margin: 0;
  padding: 0 $half-spacing-unit;
  text-align: center;
}

.benefit--discuss {
  background: url(images/discuss.png) no-repeat 50% 25%;
  background-size: 67px 60px;
}

.benefit--cloud,
.benefit--share {
  background: url(images/cloud.png) no-repeat 50% 25%;
  background-size: 74px 60px;
}

.benefit--tick-circle,
.benefit--consent {
  background: url(images/tick-circle.png) no-repeat 50% 25%;
  background-size: 60px;
}

.benefit--stethoscope {
  background: url(images/stethoscope.png) no-repeat 50% 25%;
  background-size: 54px 60px;
}

.benefit--doctor {
  background: url(images/doctor.png) no-repeat 50% 25%;
  background-size: 53px 60px;
}

.benefit--group {
  background: url(images/group.png) no-repeat 50% 25%;
  background-size: 66px 60px;
}

.benefit--mortar-board {
  background: url(images/mortar-board.png) no-repeat 50% 25%;
  background-size: 90px 60px;
}

.benefit--device {
  background: url(images/device.png) no-repeat 50% 25%;
  background-size: 34px 60px;
}

@include media-query(retina) {
  .benefit--discuss {
    background: url(images/discuss@2x.png) no-repeat 50% 25%;
    background-size: 67px 60px;
  }
  .benefit--cloud,
  .benefit--share {
    background: url(images/cloud@2x.png) no-repeat 50% 25%;
    background-size: 74px 60px;
  }
  .benefit--tick-circle,
  .benefit--consent {
    background: url(images/tick-circle@2x.png) no-repeat 50% 25%;
    background-size: 60px;
  }
  .benefit--stethoscope {
    background: url(images/stethoscope@2x.png) no-repeat 50% 25%;
    background-size: 54px 60px;
  }
  .benefit--doctor {
    background: url(images/doctor@2x.png) no-repeat 50% 25%;
    background-size: 53px 60px;
  }
  .benefit--group {
    background: url(images/group@2x.png) no-repeat 50% 25%;
    background-size: 66px 60px;
  }
  .benefit--mortar-board {
    background: url(images/mortar-board@2x.png) no-repeat 50% 25%;
    background-size: 90px 60px;
  }
  .benefit--device {
    background: url(images/device@2x.png) no-repeat 50% 25%;
    background-size: 34px 60px;
  }
}

// Signin / Join transition
.auth-actions-menu {
  transition: opacity 0.26s ease-in;
}

.landing {
  transition: opacity 0.26s ease-in;
}

.landing-transition--enter {
  .auth-actions-menu {
    opacity: 0;
  }
  .landing {
    opacity: 0;
  }
}

// Signin
@include media-query(palm) {
  .landing--login {
    padding: $base-spacing-unit $half-spacing-unit (3 * $base-spacing-unit);
    width: 70%;
    min-width: 320px;
    margin: 0 auto;
  }
  .landing__login-method--social {
    margin: $base-spacing-unit 0 0;
    .landing__description {
      border-top: 1px solid rgba(216, 223, 227, 0.5);
      padding-top: $base-spacing-unit;
    }
  }
}

@media screen and (max-width: 320px) {
  .landing--login .landing__content .fm-footer {
    text-align: center;
  }
  .landing__intro-inner,
  .landing--login {
    padding-left: $base-spacing-unit;
    padding-right: $base-spacing-unit;
  }
}

@include media-query(lap-and-up) {
  .landing--login {
    padding: $twice-spacing-unit $base-spacing-unit
      (70px + (3 * $base-spacing-unit));
  }
  .landing__login-method--medshr {
    padding-right: 8.33333%;
  }
  .landing__login-method--social {
    border-left: 1px solid rgba(216, 223, 227, 0.5);
    padding-left: 8.33333%;
  }
}

.social-auth {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

@include media-query(palm) {
  .social-auth li {
    float: left;
  }
  .social-auth-method--facebook {
    margin-right: 5px;
  }
}

.social-auth .social-auth-method a,
.social-auth .social-auth-method--google > div {
  display: block;
  width: 180px;
  height: 38px;
  border-radius: 2px;
  color: #fff;
  margin-bottom: $half-spacing-unit;
}

.social-auth .social-auth-method a {
  padding-left: 38px;
  span {
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    padding: 10px;
    height: 100%;
    display: block;
    font-size: 95%;
  }
}

.social-auth .social-auth-method--google > div {
  text-align: left;
  .abcRioButtonIcon {
    margin-right: 5px;
  }
  .abcRioButtonContents {
    font-size: 15px !important;
  }
}

.social-auth .social-auth-method--google {
  &:hover {
    .abcRioButtonContents {
      text-decoration: underline;
    }
  }
}

.social-auth-method--facebook a {
  background: #1e599b url(images/facebook@2x.png) no-repeat;
  background-size: 8px 16px;
  background-position: 14px 50%;
}

.social-auth-method--linkedin a {
  background: #14a2da url(images/in@2x.png) no-repeat center left;
  background-size: 16px;
  background-position: 12px 50%;
}

.landing--login {
  a {
    color: #ccd;
  }
  h1 {
    display: inline;
  }
}

@include media-query(lap-and-up) {
  .landing--login {
    > header {
      margin-left: 16.66667%;
    }
  }
}

.landing--login__forget {
  font-size: 80%;
}

.landing--login-join,
.landing--join-profile,
.landing--colleagues {
  h1 {
    text-align: center;
    margin-left: 0;
    margin-bottom: 0.5em;
  }
}

.landing--join-pro-email {
  h1 {
    text-align: left;
    margin-left: 8.333333%;
  }
}

@include media-query(palm) {
  .landing--login-join,
  .landing--join-profile,
  .landing--colleagues {
    h1 {
      margin-bottom: 0.5em;
    }
  }
}

.landing--join-profile {
  h1 {
    margin-top: 0.5em;
  }
}

.landing--colleagues {
  h1 {
    margin-top: 0;
  }
}

$grid_num: 12;
$grid_col_width: math.div(100%, $grid_num);
.landing--join-profile {
  fieldset {
    padding-top: $half-spacing-unit;
  }
  form > .editor-errors {
    display: none;
  }
  form > fieldset {
    margin: 0;
  }
  .fm-section--name,
  .fm-fields--profile {
    margin-left: $grid_col_width;
    width: 10 * $grid_col_width;
  }
  .fm-fields--profile {
    .fm-property-primary_spec,
    .fm-property-primary_subspec {
      float: none;
    }
    .fm-property-primary_subspec {
      margin: $base-spacing-unit 0 0;
      legend {
        font-size: 100%;
      }
    }
  }
  .auth-profile__social-auth-description {
    margin-bottom: $half-spacing-unit;
  }
  .social-auth-method {
    display: inline-block;
  }
  .fm-section--name {
    label {
      @include visually-hidden();
    }
  }
  .pro-email-section--form,
  .fm-section--profile {
    clear: left;
    color: $base-foreground-color;
    //background-color : #ccc;
    background-color: rgba(255, 255, 255, 0.8);
    padding: $base-spacing-unit 0;
    legend {
      @include inuit-font-size($inuit-heading-size-3, 1.3);
    }
    label {
      font-weight: normal;
    }
    select {
      width: 100%;
    }
  }
  .auth-profile__social-auth {
    @extend %clearfix;
  }
}

@include media-query(small-portable) {
  .landing--join-profile {
    .auth-profile__social-auth {
      border-bottom: 1px solid rgba(216, 223, 227, 0.5);
      padding-bottom: $base-spacing-unit;
      margin-bottom: $base-spacing-unit;
    }
  }
}

@include media-query(lap-and-up) {
  .landing--join-profile {
    .fm-property-first_name,
    .fm-property-last_name {
      float: left;
      width: 49%;
    }
    .fm-property-first_name {
      margin-right: $half-spacing-unit;
    }
  }
  .fm-fields--profile {
    .fm-field {
      float: left;
      width: 45%;
      margin-right: $half-spacing-unit;
    }
    .fm-property-registration_id {
      clear: left;
    }
    .fm-property-job_title--other {
      float: none;
      clear: left;
    }
    .fm-property-main_institution {
      width: 45%;
      margin-right: 0;
    }
  }
}

@include media-query(large-lap-and-up) {
  .landing--join-profile {
    .fm-fields--name {
      float: left;
      width: 58%;
    }
    .fm-property-first_name,
    .fm-property-last_name {
      width: 45%;
    }
    .auth-profile__social-auth {
      float: right;
      border-left: 1px solid rgba(216, 223, 227, 0.5);
      padding-left: 4.5%;
    }
  }
  //.fm-fields--profile {
  //  .fm-field {
  //    width: 26%;
  //  }
  //
  //  .fm-property-main_institution {
  //    width: 45%;
  //  }
  //}
}

@include media-query(desk) {
  .landing--join-profile {
    .fm-property-first_name,
    .fm-property-last_name {
      width: 48%;
    }
  }
  .fm-fields--profile {
    .fm-field {
      float: left;
      width: 34%;
      max-width: 330px;
      margin-right: $half-spacing-unit;
    }
  }
}

.auth-profile-editor {
  .fm-footer {
    clear: left;
    padding: 0 $grid_col_width $twice-spacing-unit;
  }
}

@include media-query(lap-and-up) {
  .auth-profile-editor {
    .fm-footer {
      color: $base-foreground-color;
      background-color: #ccc;
      background-color: rgba(255, 255, 255, 0.8);
    }
    .editor-errors {
      display: inline;
      margin-left: 1em;
    }
  }
}

@include media-query(palm) {
  .auth-profile-editor {
    .editor-errors {
      margin: $base-spacing-unit;
      padding: $half-spacing-unit;
      background: rgba(255, 255, 255, 0.4);
      border-radius: 3px;
    }

    .fm-footer {
      padding: $base-spacing-unit 0 !important;
      margin: 0;
      background-color: rgba(255, 255, 255, 0.8) !important;
    }
  }
  .fm-section--profile {
    //margin-bottom : $base-spacing-unit;
  }
}

.pro-email-section--intro,
.pro-email-section--form form {
  margin-left: $grid_col_width;
  width: 10 * $grid_col_width;
}

.pro-email-section--intro {
  padding-bottom: $base-spacing-unit;
  h2 {
    @include inuit-font-size($inuit-heading-size-4, 1.3);
  }
}

.pro-email-section--form {
  @extend %clearfix;
  background-color: rgba(255, 255, 255, 0.8);
  padding: $base-spacing-unit 0;
  min-height: 300px;
  .fm-property-professional_email {
    label {
      font-weight: normal;
      @include inuit-font-size($inuit-heading-size-3, 1.3);
      margin-bottom: $base-spacing-unit;
    }
  }
}

@include media-query(desk) {
  .pro-email-section--form {
    form > fieldset {
      float: left;
      width: 45%;
      margin-right: $base-spacing-unit;
    }
    .fm-footer {
      float: left;
      padding-top: 4em;
    }
  }
}

@include media-query(palm) {
  .pro-email-section--form {
    .btn--wide {
      padding: 0 2em;
      width: auto;
    }
  }
}

.landing--colleagues {
  background: rgba(0, 22, 64, 0.8)
    linear-gradient(rgba(0, 22, 64, 0.8), rgba(9, 51, 106, 0.8));
  padding: $base-spacing-unit 0 0;
}

.verify-colleagues-section--done {
  text-align: center;
  padding-bottom: $base-spacing-unit;
}

@include media-query(small-portable) {
  .verify-colleagues-section .layout__item {
    margin-left: 0;
    padding-left: 0;
  }
  .verify-colleagues-section--intro__text {
    text-align: center;
  }
}

@include media-query(large-lap-and-up) {
  .verify-colleagues-section--done {
    text-align: right;
  }
}

.verify-colleagues-section--intro,
.verify-colleagues-section--colleagues .listing,
.verify-colleagues-section--colleagues__footer {
  margin-left: $grid_col_width;
  width: 10 * $grid_col_width;
}

.verify-colleagues-section--intro__text {
  @include inuit-font-size($inuit-heading-size-3, 1.3);
}

.verify-colleagues-section--colleagues {
  background-color: #fff;
  color: $base-foreground-color;
  padding: $base-spacing-unit 0;
  .listing {
    @extend %clearfix;
    -webkit-font-smoothing: subpixel-antialiased;
  }
  .listing-item {
    position: relative;
    padding: $base-spacing-unit 0;
    border-bottom: 1px solid $off-white;
  }
  .listing-item__title {
    font-size: $base-font-size;
    font-weight: 400;
    margin-bottom: 0;
  }
  .media__img {
    margin-right: 12px;
  }
  .profile-image--user {
    background-clip: border-box;
    border-radius: 200px;
  }
  .profile-basic__info p {
    margin: 0 0 0.25em 0;
  }
  .profile-basic__job-title {
    font-size: $mini-font-size;
    font-weight: 400;
    color: #1b6ebf;
  }
  .profile-basic__institution {
    font-size: $mini-font-size;
    font-weight: 400;
    color: #6f6f6f;
  }
  .connect-form,
  .connect-form__status {
    position: absolute;
    top: 18px;
    right: $base-spacing-unit;
  }
}

@include media-query(palm) {
  .listing-item__title {
    padding-right: 5.5em;
  }
  .verify-colleagues-section--colleagues {
    .connect-form,
    .connect-form__status {
      right: 0;
    }
  }
}

.verify-colleagues-section--colleagues__footer {
  padding: $base-spacing-unit 0;
  text-align: right;
}

@include media-query(large-lap-and-up) {
  .verify-colleagues-section--colleagues {
    .listing-item {
      float: left;
      width: 48%;
      height: 115px;
    }
    .listing-item:nth-child(even) {
      float: right;
    }
  }
  .listing-item__title {
    padding-right: $base-spacing-unit + 95px;
  }
  .profile-basic__job-title,
  .profile-basic__institution {
    padding-right: 50px;
  }
}

@include media-query(desk) {
  .verify-colleagues-section--colleagues {
    .listing-item {
      width: 48%;
    }
  }
}

.connect-form__button {
  -webkit-appearance: none;
  border: 0;
  background: transparent url(images/profile-connect__button.png) no-repeat top
    right;
  color: $light-link-color;
  text-decoration: underline;
  font-weight: 200;
  font-size: $small-font-size;
  padding: 4px 40px 8px 0;
  outline: none;
  &:hover {
    text-decoration: underline;
    background: transparent url(images/profile-connect__button--hot.png)
      no-repeat top right;
  }
}

.connect-status--connecting,
.connect-status--connected {
  display: block;
  width: 30px;
  height: 30px;
}

.connect-status--connecting {
  background: transparent url(images/profile-connecting.png) no-repeat top right;
  background-size: 30px;
  animation: spinner-spin 1s linear infinite;
  &:hover {
    background: transparent url(images/profile-connecting.png) no-repeat top
      right;
  }
}

@keyframes spinner-spin {
  from {
    transform: rotateZ(0);
  }
  to {
    transform: rotateZ(360deg);
  }
}

.connect-status--connected {
  background: transparent url(images/profile-connected.png) no-repeat top right;
  background-size: 30px;
  &:hover {
    background: transparent url(images/profile-connected.png) no-repeat top
      right;
  }
}

@include media-query(retina) {
  .connect-form__button {
    background: transparent url(images/profile-connect__button-2x.png) no-repeat
      top right;
    background-size: 30px;
    &:hover {
      background: transparent url(images/profile-connect__button-2x--hot.png)
        no-repeat top right;
    }
  }
  .connect-status--connecting {
    background: transparent url(images/profile-connecting-2x.png) no-repeat top
      right;
    background-size: 30px;
    animation: spinner-spin 1s linear infinite;
    &:hover {
      background: transparent url(images/profile-connecting-2x.png) no-repeat
        top right;
    }
  }
  .connect-status--connected {
    background: transparent url(images/profile-connected-2x.png) no-repeat top
      right;
    background-size: 30px;
    &:hover {
      background: transparent url(images/profile-connected-2x.png) no-repeat top
        right;
    }
  }
}

.connect-form__status {
  height: 30px;
  width: 30px;
}
