fieldset {
  margin: 0 0 $base-spacing-unit 0;
  border: 0;
}

input,
label,
select {
  display: block;
  font-family: $form-font-family;
  font-size: $form-font-size;
}

label {
  cursor: pointer;
  font-weight: bold;
  margin-bottom: $base-spacing-unit * 0.25;

  &.required:after {
    content: '*';
  }

  abbr {
    display: none;
  }
}

textarea,
#{$all-text-inputs},
select,
select[multiple='multiple'] {
  box-sizing: border-box;
  transition: border-color;
  background-color: white;
  //border-radius: $form-border-radius;
  border: 1px solid $form-border-color;
  box-shadow: $form-box-shadow;
  font-family: $form-font-family;
  font-size: $form-font-size;
  margin-bottom: $base-spacing-unit * 0.5;
  padding: 10px;
  width: 100%;

  &:hover {
    border-color: $form-border-color-hover;
  }

  &:focus {
    border-color: $form-border-color-focus;
    box-shadow: $form-box-shadow-focus;
    outline: none;
  }
}

select {
  max-width: 100%;
  width: auto;
  font-size: 90%;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 10px;
  border-radius: 2px;
  cursor: pointer;
  background: #fff url(images/caret@3x.png) no-repeat center right;
  background-size: 21px 6px;
}

textarea {
  resize: vertical;
}

input[type='search'] {
  appearance: none;
}

.radio label,
.checkbox label {
  display: inline;
  font-weight: normal;
  font-size: 90%;
  margin-right: 1em;
}

input[type='checkbox'],
input[type='radio'] {
  cursor: pointer;
  display: inline;
  margin-right: 0.25em;
}

input[type='file'] {
  padding-bottom: $half-spacing-unit;
  width: 100%;
}

.fm-description {
  color: $low-text-color;
}

.auth-form .error,
.editor-errors {
  color: #f30;
  a {
    color: #f30;
  }
}

.auth-reset__form .error {
  color: #fff;
  list-style-type: none;
  margin-left: 0;
  padding-left: 0;
  li {
    margin-bottom: 0.5em;
  }
}

.fm-error {
  textarea,
  #{$all-text-inputs},
  select {
    border-color: $form-border-color-error;
    border-width: 2px;
  }
}

.fm-field {
  transition: opacity 0.16s ease-in;
  .error,
  .bad {
    color: #f30;
    font-size: 90%;
    margin-top: -0.5em;
    margin-bottom: 0.75em;
  }
}

.fm-field--inline {
  label {
    display: inline-block;
    margin-right: 1em;
  }
  select {
    display: inline-block;
    padding-right: 40px;
  }
}

.fm-field--disabled {
  opacity: 0.5;
  label,
  select,
  input,
  button {
    cursor: default !important;
  }
}

.ui-autocomplete-input {
  z-index: 100;
  position: relative;
  height: 41px;
}

.ui-autocomplete {
  background: #fff;
  border-bottom-left-radius: $form-border-radius;
  border-bottom-right-radius: $form-border-radius;
  border: 1px solid $form-border-color;

  float: left;

  z-index: 1000000;

  max-height: 15em;
  overflow: auto;

  .ui-menu-item {
    cursor: pointer;
    padding: 5px 10px;
  }

  .ui-state-focus,
  .ui-menu-item:active,
  .ui-menu-item:focus,
  .ui-menu-item:hover {
    background-color: $brand-color;
    color: #fff;
    a {
      background-color: $brand-color;
      color: #fff;
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
    }
  }

  .profile-image {
    margin-top: 5px;
  }
  .members-item__title {
    font-size: 120%;
    margin: 0;
  }
  .members-item__date {
    font-size: 100%;
    color: $low-text-color;
  }
}

.ui-autocomplete ul,
ul.ui-autocomplete {
  background: #fff;
  list-style-type: none;
  margin: 0;
  padding: 0;
  li {
    margin: 0;
  }
}

.ui-helper-hidden-accessible {
  @extend .accessibility;
}

.cms-editor-datetimefield,
.cms-editor-datefield {
  position: relative;
  z-index: 1000;
  label,
  input {
    display: inline;
    margin-right: 0.5em;
  }
}

input.dt {
  width: 7em;
}

.cms-date-label {
  @extend .accessibility;
}

.datepicker {
  th,
  td {
    font-size: 90%;
    padding: 5px 7px;
  }
}

.cms-editor-stringfield .small {
  width: 8em;
}

.cms-editor-textfield {
  textarea {
    height: 8em;
  }
  .small {
    height: 4.5em;
  }
  .large {
    height: 14.5em;
  }
  .x-large {
    height: 28.5em;
  }
}

.fm-property-avatar {
  overflow: auto;
  .cms-editor-file-preview {
    float: left;
    margin-right: $base-spacing-unit;
    overflow: hidden;
    border-radius: 200px;
  }
  .file {
    width: auto;
  }
}

.editor-models__picker {
  .listing {
    @extend %clearfix;
  }

  .listing-item {
    cursor: pointer;

    .editor-models__item-detail {
      display: block;
      margin: 5px 0;
      color: $low-text-color;
      font-size: 80%;
      padding: 15px 0 13px 46px;
    }
    input[type='checkbox'] {
      @include visually-hidden();
    }
    input[type='checkbox'] + .editor-models__item-detail {
      background: transparent url(images/checkbox-off@2x.png) no-repeat center
        left;
      background-size: 36px 36px;
    }

    input[type='checkbox']:checked + .editor-models__item-detail {
      background: transparent url(images/checkbox-on@2x.png) no-repeat center
        left;
      background-size: 36px 36px;
      color: $base-text-color;
    }
  }

  .listing-item--create-profile {
    @include inuit-font-size($inuit-heading-size-3, 1.3);
    .editor-models__item-detail {
      color: $base-text-color;
    }
  }

  .ui-sortable-helper {
    cursor: move;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }
}

.link-button {
  border: 0;
  background: transparent none;
  color: $link-color;
  padding: 0;
  &:hover {
    text-decoration: underline;
  }
}
