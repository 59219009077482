.listing--cases {
  margin: 20px auto 0;
  max-width: 40em;
  padding: 1em 1em 100px;

  .listing__item {
    margin-bottom: 1em;
  }
  .case__categories {
    display: block;
    font-size: 90%;
  }
}
