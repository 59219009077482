@use "sass:math";

.device-preview {
  width: 318px;
  height: 559px;
  background: url(images/iphone.png) no-repeat top center;
  background-size: 267px;
}

@include media-query(retina) {
  .device-preview {
    background: url(images/iphone@2x.png) no-repeat top center;
    background-size: 267px;
  }
}

$device-preview-width: 235px;
$device-preview-height: 415px;

$real-device-ratio: math.div(234, 320);

.device-preview__viewport {
  width: $device-preview-width;
  height: $device-preview-height;
  overflow: hidden;
  position: absolute;
  top: 65px;
  left: 42px;
}

.device-preview__statusbar {
  position: absolute;
  top: 0;
  left: 0;
  width: $device-preview-width;
  height: 20px * $real-device-ratio;
  font-family: 'San Francisco', "Helvetica Neue", Arial, sans-serif;
  font-size: 10px;
  -moz-font-smoothing: auto;
  -webkit-font-smoothing: subpixel-antialiased;
  opacity: 0;
  transition: opacity 0.26s ease-out;
}

.device-preview__statusbar--visible {
  opacity: 0.8;
}

.device-preview__signal {
  display: block;
  position: absolute;
  top: 5px;
  left: 5px;
  width: 0.5 * 114px * $real-device-ratio;
  height: 0.5 * 24px * $real-device-ratio;
  background: url(images/iphone/signal-wifi@2x.png) no-repeat;
  background-size: contain;
}

.device-preview__battery {
  display: block;
  position: absolute;
  top: 4px;
  right: 5px;
  width: 0.5 * 73px * $real-device-ratio;
  height: 0.5 * 27px * $real-device-ratio;
  background: url(images/iphone/battery@2x.png) no-repeat;
  background-size: contain;
}

.device-preview__time {
  display: block;
  text-align: center;
  padding-top: 3px;
}

.device-preview__screen {
  line-height: 1.2;
  width: $device-preview-width;
  height: $device-preview-height;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX($device-preview-width);
  transition: transform 0.26s ease-in-out, opacity 0.26s ease-in;
}

.device-preview__screen--splash {
  background: url(images/iphone/splash.jpg) no-repeat top left;
  background-size: cover;
  transform: translateX(0);
  opacity: 0;
}

.device-preview__screen--splash.device-preview__screen--active {
  opacity: 1;
}

.device-preview__screen--resource {
  background-color: #fff;
  font-size: 12px;
}

.device-preview__navbar {
  height: 44px * $real-device-ratio;
  position: absolute;
  top: 20px * $real-device-ratio;
  left: 0;
  width: 100%;
}

.device-preview__navbutton {
  width: 18px * $real-device-ratio;
  height: 18px * $real-device-ratio;
  position: absolute;
  top: 9px;
}

.navbutton--back {
  background: url(images/iphone/tool-back@2x.png) no-repeat;
  background-size: contain;
  left: 10px;
}

.navbutton--share {
  background: url(images/iphone/tool-share@2x.png) no-repeat;
  background-size: contain;
  right: 38px;
}

.navbutton--more {
  background: url(images/iphone/tool-more@2x.png) no-repeat;
  background-size: contain;
  right: 10px;
}

.device-preview__resource-header {
  background: $dark-background-color
    linear-gradient($dark-background-color, $base-accent-color);
  padding: 50px 10px 10px;
  font-weight: 200;
  pointer-events: none;
  user-select: none;
}

.device-preview__resource-body {
  padding: 10px;
  font-family: Georgia, serif;
  color: $base-text-color;
  pointer-events: none;
  user-select: none;
}

.ps-app-cases .device-preview__resource-description {
  filter: blur(3px);
  pointer-events: none;
  user-select: none;
}

.ps-app-cases .device-preview__resource-description--unblurred {
  filter: none;
}

.device-preview__resource-creator-name {
  padding-top: 2px;
}

.device-preview__resource-creator-image {
  border: 2px solid rgba(255, 255, 255, 0.8);
  border-radius: 100px;
}

.device-preview__resource-creator-job_title,
.device-preview__resource-meta,
.device-preview__resource-categories {
  color: rgba(255, 255, 255, 0.5);
  font-size: 11px;
}

.device-preview__resource-title {
  font-size: 24px;
  margin: 0.5em 0;
}

.device-preview__resource-categories {
  margin: 0.5em 0;
}

.device-preview__group-header {
  height: 100%;
  padding: 50px 10px 25px;
  text-align: center;
}

.device-preview__group-profile-image {
  display: block;
  margin: 0 auto $base-spacing-unit;
  border-radius: 200px;
}

.device-preview__group-title,
.device-preview__learning-title {
  font-size: 120%;
  margin-bottom: 1em;
}

.device-preview__group-description,
.device-preview__learning-description {
  font-size: 80%;
  max-height: 6em;
  overflow: hidden;
  margin-bottom: 1em;
}

.device-preview__screen--leaving {
  transform: translateX(-($device-preview-width * 0.25)) !important;
}

.device-preview__screen--entering,
.device-preview__screen--active {
  transform: translateX(0);
}

.device-preview__screen--learning_module {
  .device-preview__navbar {
    z-index: 1;
  }

  .device-preview__resource-creator {
    position: relative;
    z-index: 1;
    text-align: left;
    display: inline-block;
    margin: 0 auto 20px;
  }

  .device-preview__resource-creator-image {
    border: 0;
  }
  .device-preview__resource-creator-name {
    font-size: 90%;
    padding-top: 4px;
  }
}

.device-preview__learning-logo {
  position: relative;
  z-index: 1;
  margin-top: 10px;

  background: transparent url(images/logo-white.png) no-repeat center center;
  background-size: contain;
  width: 50px;
  height: 45px;
  display: block;
  margin: 17px auto 0;
  text-indent: 100px;
  overflow: hidden;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.device-preview__learning-title {
  position: relative;
  z-index: 1;
  margin: 10px 0;
}

.device-preview__learning-description {
  position: relative;
  z-index: 1;
}
