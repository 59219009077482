.page-footer {
  clear: both;
  border-top: $base-spacing-unit - 10 solid #021740;
  position: relative;
  background: #021740;
}

.footer-your-shortcuts {
  @include responsive-page-width();
  padding: $base-spacing-unit 0 $twice-spacing-unit;
  border-top: $separator-border;
  @extend %clearfix;
}

.footer-your-shortcuts__nav-list {
  list-style-type: none;
  margin: 0;
}

@include media-query(palm) {
  .footer-your-content {
    margin: 0 $base-spacing-unit $base-spacing-unit;
  }
  .footer-your-network {
    margin: 0 $base-spacing-unit $base-spacing-unit;
  }
}

@include media-query(lap-and-up) {
  .footer-your-content {
    margin-left: 15%;
    margin-right: 5%;
    float: left;
    width: 38%;
  }
  .footer-your-network {
    float: left;
  }
}

.footer-links {
  background: $dark-background-color;
  min-height: 100px;

  @media screen and (min-height: 968px) and (max-height: 2000px) {
    min-height: 232px;
  }
  /* Portrait */
  @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
    min-height: 480px;
  }
  /* Landscape */
  @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
    min-height: 160px;
  }
}

.footer-site-links {
  @extend %clearfix;
}

.footer-site-links__nav-list {
  color: $low-text-color;
  list-style-type: none;
  margin: 0;
  padding: $base-spacing-unit;
  a {
    color: #fff;
    font-weight: 300;
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: grayscale;
  }

  /* ----------- Retina Screens ----------- */
  @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
    a {
      font-weight: 300;
      -webkit-font-smoothing: unset;
      -moz-osx-font-smoothing: unset;
    }
  }

  .footer-site-link--contact {
    a {
      color: $light-link-color;
    }
  }
}

@include media-query(portable) {
  .footer-site-link--contact {
    margin-bottom: $base-spacing-unit;
  }
  .footer-site-link--copyright {
    margin: $base-spacing-unit 0;
  }
}

@include media-query(lap) {
  .footer-site-links__nav-list {
    padding: $base-spacing-unit $twice-spacing-unit;
  }
}

@include media-query(desk) {
  .footer-site-links__nav-list {
    li {
      display: block;
      float: left;
      margin-right: 1.6em;
    }
    .footer-site-link--contact {
      float: right;
      margin-right: 0;
    }
    .footer-site-link--copyright {
      float: right;
    }
    .footer-site-link--mixpanel {
      clear: both;
      float: left;
      margin: 22px 0;
    }
  }
}

.footer-content-links {
  border-bottom: 1px solid #3f4b78;
  overflow: auto;
}

.footer-content-links__nav-list {
  @extend .pack;
  margin: 0;
  li {
    @extend .pack__item;
  }
}

@include media-query(palm) {
  .footer-content-links__nav-list {
    padding: 0;
    li {
      width: 33.3333%;
      float: left;
      display: block;
    }
  }
}

.footer-content-links__nav-list {
  a {
    color: $light-link-color;
    display: block;
    margin: 0 2px;
    border-top: 10px solid $light-link-color;
    text-align: center;
    padding: $half-spacing-unit 0 $base-spacing-unit;
  }
}

.footer-site-links .goog-te-gadget .goog-te-combo {
  padding: 5px 10px;
}
