@mixin responsive-page-width(
  $width: $page-max-width,
  $padding: $base-spacing-unit
) {
  max-width: $width;
  width: 100%;
  margin: 0 auto;
  @if ($padding > 0) {
    padding-left: $padding;
    padding-right: $padding;
  }
}
