a {
  color: $link-color;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.dark-background {
  a {
    color: $light-link-color;
  }
}
