/*
 * Settings
 */

@import 'settings.global';
@import 'bower_components/inuit-defaults/settings.defaults';
@import 'settings.responsive';

/*
 * Tools
 */

$inuit-widths-delimiter: null;

@import 'bower_components/inuit-functions/tools.functions';
@import 'bower_components/inuit-mixins/tools.mixins';
@import 'bower_components/inuit-responsive-tools/tools.responsive';
@import 'bower_components/inuit-tools-widths/tools.widths';
@import 'tools.aliases';
@import 'tools.html5-input-types';
@import 'tools.layout';
@import 'tools.brand';
@import 'tools.mixins';
@import 'tools.css-arrow';
@import 'tools.hide';

/*
 * Generic
 */

@import 'bower_components/inuit-normalize/generic.normalize';
@import 'bower_components/inuit-reset/generic.reset';
@import 'bower_components/inuit-box-sizing/generic.box-sizing';
@import 'bower_components/inuit-shared/generic.shared';
@import 'generic.menu';

/*
 * Base
 */

@import 'base.page';
@import 'bower_components/inuit-images/base.images';
@import 'bower_components/inuit-lists/base.lists';
@import 'base.type';
@import 'base.forms';
@import 'base.forms-guest';
@import 'base.links';
@import 'base.lists';

/*
 * Objects
 */

$inuit-enable-layout--flush: true;

@import 'bower_components/inuit-layout/objects.layout';
@import 'bower_components/inuit-media/objects.media';
@import 'bower_components/inuit-pack/objects.pack';
@import 'bower_components/inuit-list-inline/objects.list-inline';
@import 'bower_components/inuit-list-bare/objects.list-bare';
@import 'objects.buttons';
@import 'objects.nav';
@import 'objects.tabs';

@import 'bower_components/font-awesome/scss/font-awesome';

/*
 * Components
 */

@import 'components.alerts';
@import 'components.dropdown';

@import 'components.modal';

.page-content {
  background: #fff;
  .page-fixwidth {
    padding-top: 102px;
  }
}

@import 'guest/components.apple-signin';
@import 'guest/components.google-signin';
@import 'guest/pages.landing';
@import 'guest/pages.page';
@import 'guest/components.device';
@import 'guest/components.signup';
@import 'guest/components.subspecialty-picker';
@import 'guest/components.case-list';

@import 'components.page';
@import 'guest/components.header';
@import 'components.footer';

@import 'components.specialty-picker';

/*
 * Trumps
 */

@import 'bower_components/inuit-clearfix/trumps.clearfix';

@import 'bower_components/inuit-widths/trumps.widths';
@import 'bower_components/inuit-widths-responsive/trumps.widths-responsive';

@import 'trumps.hide';
@import 'trumps.fix-webkit-autofill-color';

.smartbanner-show {
  margin-top: 80px;
}

.smartbanner-show .smartbanner {
  display: block;
}

/** Default **/
.smartbanner {
  position: absolute;
  left: 0;
  top: -80px;
  display: none;
  width: 100%;
  height: 80px;
  line-height: 80px;
  font-family: 'Helvetica Neue', sans-serif;
  background: #f4f4f4;
  z-index: 9998;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
  -webkit-text-size-adjust: none;
}

.smartbanner-container {
  margin: 0 auto;
  white-space: nowrap;
}

.smartbanner-close {
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px 0 5px;
  font-family: 'ArialRoundedMTBold', Arial;
  font-size: 20px;
  text-align: center;
  color: #888;
  text-decoration: none;
  border: 0;
  border-radius: 14px;
  -webkit-font-smoothing: subpixel-antialiased;
}

.smartbanner-close:active,
.smartbanner-close:hover {
  color: #aaa;
}

.smartbanner-icon {
  display: inline-block;
  vertical-align: middle;
  width: 57px;
  height: 57px;
  margin-right: 12px;
  background-size: cover;
  border-radius: 10px;
}

.smartbanner-info {
  display: inline-block;
  vertical-align: middle;
  width: 44%;
  font-size: 11px;
  line-height: 1.2em;
  font-weight: bold;
}

.smartbanner-title {
  font-size: 13px;
  line-height: 18px;
}

.smartbanner-button {
  position: absolute;
  right: 20px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  height: 24px;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  font-weight: bold;
  color: #6a6a6a;
  text-transform: uppercase;
  text-decoration: none;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
}

.smartbanner-button:active,
.smartbanner-button:hover {
  color: #aaa;
}

.smartbanner-button-text {
}

.smartbanner-button-text:active,
.smartbanner-button-text:hover {
}

/** iOS **/
.smartbanner-ios {
  background: #f4f4f4;
  background: linear-gradient(to bottom, #f4f4f4, #cdcdcd);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  line-height: 80px;
}

.smartbanner-ios .smartbanner-close {
  border: 0;
  width: 18px;
  height: 18px;
  line-height: 18px;
  color: #888;
  text-shadow: 0 1px 0 white;
}

.smartbanner-ios .smartbanner-close:active,
.smartbanner-ios .smartbanner-close:hover {
  color: #aaa;
}

.smartbanner-ios .smartbanner-icon {
  background: rgba(0, 0, 0, 0.6);
  background-size: cover;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}

.smartbanner-ios .smartbanner-info {
  color: #6a6a6a;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
}

.smartbanner-ios .smartbanner-title {
  color: #4d4d4d;
  font-weight: bold;
}

.smartbanner-ios .smartbanner-button {
  padding: 0 10px;
  min-width: 10%;
  color: #6a6a6a;
  background: #efefef;
  background: linear-gradient(to bottom, #efefef, #dcdcdc);
  border-radius: 3px;
  box-shadow: inset 0 0 0 1px #bfbfbf, 0 1px 0 rgba(255, 255, 255, 0.6),
    0 2px 0 rgba(255, 255, 255, 0.7) inset;
}

.smartbanner-ios .smartbanner-button:active,
.smartbanner-ios .smartbanner-button:hover {
  background: #dcdcdc;
  background: linear-gradient(to bottom, #dcdcdc, #efefef);
}

.smartbanner-ios .smartbanner-button-text {
}

.smartbanner-ios .smartbanner-button-text:active,
.smartbanner-ios .smartbanner-button-text:hover {
}

/** Android **/
.smartbanner-android {
  background: #3d3d3d
    url(data:image/gif;base64,R0lGODlhCAAIAIABAFVVVf///yH5BAEHAAEALAAAAAAIAAgAAAINRG4XudroGJBRsYcxKAA7);
  box-shadow: inset 0 4px 0 #88b131;
  line-height: 82px;
}

.smartbanner-android .smartbanner-close {
  border: 0;
  width: 17px;
  height: 17px;
  line-height: 17px;
  margin-right: 7px;
  color: #b1b1b3;
  background: #1c1e21;
  text-shadow: 0 1px 1px #000;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.8) inset,
    0 1px 1px rgba(255, 255, 255, 0.3);
}

.smartbanner-android .smartbanner-close:active,
.smartbanner-android .smartbanner-close:hover {
  color: #eee;
}

.smartbanner-android .smartbanner-icon {
  background-color: transparent;
  box-shadow: none;
}

.smartbanner-android .smartbanner-info {
  color: #ccc;
  text-shadow: 0 1px 2px #000;
}

.smartbanner-android .smartbanner-title {
  color: #fff;
  font-weight: bold;
}

.smartbanner-android .smartbanner-button {
  min-width: 12%;
  color: #d1d1d1;
  padding: 0;
  background: none;
  border-radius: 0;
  box-shadow: 0 0 0 1px #333, 0 0 0 2px #dddcdc;
}

.smartbanner-android .smartbanner-button:active,
.smartbanner-android .smartbanner-button:hover {
  background: none;
}

.smartbanner-android .smartbanner-button-text {
  text-align: center;
  display: block;
  padding: 0 10px;
  background: #42b6c9;
  background: linear-gradient(to bottom, #42b6c9, #39a9bb);
  text-transform: none;
  text-shadow: none;
  box-shadow: none;
}

.smartbanner-android .smartbanner-button-text:active,
.smartbanner-android .smartbanner-button-text:hover {
  background: #2ac7e1;
}

/** Windows **/
.smartbanner-windows {
  background: #f4f4f4;
  background: linear-gradient(to bottom, #f4f4f4, #cdcdcd);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  line-height: 80px;
}

.smartbanner-windows .smartbanner-close {
  border: 0;
  width: 18px;
  height: 18px;
  line-height: 18px;
  color: #888;
  text-shadow: 0 1px 0 white;
}

.smartbanner-windows .smartbanner-close:active,
.smartbanner-windows .smartbanner-close:hover {
  color: #aaa;
}

.smartbanner-windows .smartbanner-icon {
  background: rgba(0, 0, 0, 0.6);
  background-size: cover;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}

.smartbanner-windows .smartbanner-info {
  color: #6a6a6a;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
}

.smartbanner-windows .smartbanner-title {
  color: #4d4d4d;
  font-weight: bold;
}

.smartbanner-windows .smartbanner-button {
  padding: 0 10px;
  min-width: 10%;
  color: #6a6a6a;
  background: #efefef;
  background: linear-gradient(to bottom, #efefef, #dcdcdc);
  border-radius: 3px;
  box-shadow: inset 0 0 0 1px #bfbfbf, 0 1px 0 rgba(255, 255, 255, 0.6),
    0 2px 0 rgba(255, 255, 255, 0.7) inset;
}

.smartbanner-windows .smartbanner-button:active,
.smartbanner-windows .smartbanner-button:hover {
  background: #dcdcdc;
  background: linear-gradient(to bottom, #dcdcdc, #efefef);
}

.smartbanner-windows .smartbanner-button-text {
}

.smartbanner-windows .smartbanner-button-text:active,
.smartbanner-windows .smartbanner-button-text:hover {
}
