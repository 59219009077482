.btn {
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
  font-family: inherit;
  font-size: 100%;
  line-height: 2;
  cursor: pointer;
  border: none;
  margin: 0;
  padding-top: 1px;
  padding-bottom: 0;
  padding-right: 1em;
  padding-left: 1em;
  text-decoration: none;
  border-radius: $brand-round;
}

.btn:hover {
  text-decoration: none;
}

.btn {
  color: #1b6ebf;
  background-color: transparent;
  border: 2px solid;
  border-radius: 200px;
  transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out,
    border-color 0.1s ease-in-out;
}

.btn:hover {
  color: #fff;
  background-color: #1b6ebf;
  border-color: #1b6ebf;
  transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out,
    border-color 0.1s ease-in-out;
}

.btn--delete,
.btn--danger,
.btn-danger {
  color: $danger-color;
  background-color: transparent;
  border: 2px solid;
}

.btn--delete:hover,
.btn--danger:hover,
.btn-danger:hover {
  color: #fff;
  background-color: $danger-color;
  border-color: $danger-color;
}

.btn[disabled],
.btn--disabled {
  color: #ccc !important;
  background-color: transparent !important;
  border: 2px solid !important;
  cursor: default;
}

.btn--pill[disabled],
.btn--pill.btn--disabled {
  background-color: #999 !important;
  color: #fff !important;
  border: 2px solid !important;
  opacity: .5;
  cursor: default;
}

.btn-cancel,
.btn--low {
  color: $low-text-color;
  background-color: transparent;
  border: 2px solid;
}

.btn-cancel:hover,
.btn--low:hover {
  color: #fff;
  background-color: $low-text-color;
  border-color: $low-text-color;
}

.btn--white {
  color: #fff;
  background-color: transparent;
  border: 2px solid;
}

.btn--white:hover {
  color: $base-text-color;
  background-color: #fff;
  border-color: #fff;
}

.btn--dark {
  color: $base-text-color;
  border-color: $base-text-color;
  background-color: transparent;
  border: 2px solid;
}

.btn--dark:hover {
  color: #fff;
  background-color: $base-text-color;
  border-color: $base-text-color;
}

.btn--small {
  line-height: 2;
  font-size: 80%;
}

.btn--wide {
  padding-left: 3em;
  padding-right: 3em;
}

.btn--pill {
  background-color: $light-link-color;
  color: #fff;
  font-weight: 400;
  border-color: $light-link-color;
}
.dark-background {
  a.btn--pill {
    color: #fff;
  }
}

.btn--pill:hover {
  background-color: #1b6ebf;
  color: #fff;
  border-color: #1b6ebf;
}

.dark-background {
  .btn--pill,
  .btn--pill:hover {
    border-color: #fff;
  }
}

.btn--on-dark,
.btn--on-dark:hover {
  border-color: #fff;
}

.btn-delete.btn--pill,
.btn-danger.btn--pill {
  background-color: $danger-color;
  color: #fff;
}

.btn--dark.btn--pill {
  background-color: $base-text-color;
  border-color: $base-text-color;
  color: #fff;
}

.btn--dark.btn--pill:hover {
  background-color: $base-accent-color;
  border-color: $base-accent-color;
}

.btn--white.btn--pill {
  background-color: #fff;
  border-color: #fff;
  color: $base-text-color;
}

.btn--white.btn--pill:hover {
  background-color: transparent;
  border-color: #fff;
  color: #fff;
}

.btn--apply {
  background-color: $apply-color;
  border-color: $apply-color;
  color: #fff;
}

.btn--apply:hover {
  background-color: darken($apply-color, 10%);
  border-color: darken($apply-color, 10%);
}

.btn--save {
  background-color: #46a7ff;
  border-color: #46a7ff;
  color: #fff;
}

.btn .fa {
  font-size: 120%;
}
