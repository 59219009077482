#appleid-signin {
  width: 180px;
  height: 38px;
  margin-bottom: 12px;
  cursor: pointer;
}

#appleid-signin > div {
  width: 180px;
  height: 38px;
}
