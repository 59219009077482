.page-fixwidth {
  @include responsive-page-width();
  padding: $base-spacing-unit;
}

.layout-guest .page-body {
  background-color: $base-background-color;
}

table.privacy th,
table.privacy td {
  vertical-align: top;
  padding: 0 0.5em 0 0;
}
