html {
  font-family: $brand-face;
}

@include headings() {
  @include font-weight(regular);
}

h1 {
  @include inuit-font-size($inuit-heading-size-1, 1.3);
}

h2 {
  @include inuit-font-size($inuit-heading-size-2, 1.3);
}

h3 {
  @include inuit-font-size($inuit-heading-size-3, 1.3);
}

h4 {
  @include inuit-font-size($inuit-heading-size-4, 1.3);
}

h5 {
  @include inuit-font-size($inuit-heading-size-5, 1.3);
}

h6 {
  @include inuit-font-size($inuit-heading-size-6, 1.3);
}

@include media-query(palm) {
  h1 {
    @include inuit-font-size($inuit-heading-size-1--palm, 1.3);
  }

  h2 {
    @include inuit-font-size($inuit-heading-size-2--palm, 1.3);
  }

  h3 {
    @include inuit-font-size($inuit-heading-size-3--palm, 1.3);
  }

  h4 {
    @include inuit-font-size($inuit-heading-size-4--palm, 1.3);
  }

  h5 {
    @include inuit-font-size($inuit-heading-size-5--palm, 1.3);
  }

  h6 {
    @include inuit-font-size($inuit-heading-size-6--palm, 1.3);
  }
}

hr {
  margin: $base-spacing-unit 0;
  border: 0;
  border-top: 1px solid $low-text-color;
}
