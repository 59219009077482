.fm-property-primary_subspec {
  label {
    display: block;
    margin: 5px 0;
    color: $low-text-color;
    font-size: 80%;
    padding: 15px 0 13px 46px;
  }
  input[type='checkbox'] {
    float: right;
    visibility: hidden;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px);
  }
  input[type='checkbox'] + label {
    background: transparent url(images/checkbox-blue-round-off@2x.png) no-repeat
      center left;
    background-size: 36px 36px;
  }

  input[type='checkbox']:checked + label {
    background: transparent url(images/checkbox-blue-round-on@2x.png) no-repeat
      center left;
    background-size: 36px 36px;
    color: $base-text-color;
  }
}
